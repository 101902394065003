<template>
  <v-container fluid class="pa-0">
    <banner :title="$t('productHome.title')"></banner>
    <v-container fluid>
      <v-row class="my-10 ma-7" justify="center">
        <v-col col="12" xs="12" lg="5" class="ml-0 align-self-end">
          <v-row class="py-lg-16">
            <v-img contain src="@/assets/palos-front.png" width="144"> </v-img>
          </v-row>
        </v-col>
        <v-col cols="12" lg="5" class="ml-0 align-center d-flex">
          <v-row class="py-10 py-lg-0 justify-end">
            <p class="josefin-light black-grin p header-product text-center text-lg-right"
            v-html="$t('productHome.phraseRum')">
            </p>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" class="odd py-lg-16 px-lg-16 py-10">
        <v-col col="12" xs="12" class="ml-0 py-6 px-lg-16 px-10">
          <h1 class="copperplate-medium gold h1 my-3 text-center text-lg-left">
            RON DON MIGUEL HOMENAJE
          </h1>
          <p class="josefin-light black-grin p text-lg-justify text-center"
          v-html="$t('productHome.generalDescriptionAboutProduct')"> </p>
        </v-col>
      </v-row>
      <v-row justify="center" class="px-lg-16">
        <v-list class="py-lg-16 lista">
          <v-list-item-group v-model="model">
            <v-list-item v-for="item in getProducts.data" :key="item.name" class=" py-10">
              <v-list-item-content>
                <v-col cols="12" lg="5">
                  <v-row class="imagen-row justify-center justify-lg-start">
                    <v-card class="imagen justify-lg-end">
                      <v-img :src="item.base_image.medium_image_url" class="product_image"></v-img>
                       <div v-if="!item.in_stock" class="unavailable px-10 py-1">
                        <p class="josefin-light white-black  text-lg-justify
                        text-center ma-0 mr-n3">
                          <span class="josefin-italic">{{$t('productHome.unavailable')}}</span>
                        </p>
                      </div>
                    </v-card>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="7" class="align-self-center px-10">
                  <v-row class="justify-center justify-lg-start">
                    <v-list-item-title
                      v-html="item.short_description"
                      class="h1 copperplate gold py-5 text-center text-lg-left"
                    ></v-list-item-title>
                    <v-list-item-title
                      v-text="item.name"
                      class="h4 black-grin mt-n5 mb-5 text-center text-lg-left"
                    >
                    </v-list-item-title>
                    <p
                      v-html="item.description"
                      class="p josefin-light text-lg-justify text-center"
                    ></p>
                    <v-list-item-title
                      v-text="'RD$' + Intl.NumberFormat().format(item.price) + '.00'"
                      class="h3 balck-grin mb-5 text-center text-lg-left"
                    >
                    </v-list-item-title>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="12" xl="4" lg="6">
                      <v-row class="justify-lg-start justify-center">
                        <v-btn
                          class="btn_first align-star btn-see-detail"
                          :ripple="{ class: 'white--text' }"
                          elevation="0"
                          medium
                          :to="'productos/' + item.url_key"
                          >{{$t('productHome.btnShowDetails')}}</v-btn
                        >
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="6" xl="4" class="pt-5 pt-lg-3">
                      <v-row class="justify-lg-end justify-center">
                        <v-btn
                          class="btn_second btn-shop"
                          elevation="0"
                          :ripple="{ class: 'white--text' }"
                          medium
                          :disabled="!item.in_stock || !getUser"
                          @click="addProductToCart(item.id)"
                          >{{$t('productHome.btnBuy')}}</v-btn
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-row>
       <v-alert dense v-if="alertVisible" text type="success" class="mt-5 alert-success">
        {{ $t("alerts.addProductMessage") }}
      </v-alert>
    </v-container>
  </v-container>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";
import banner from "@/components/home/banner.vue";

export default {
  components: {
    banner
  },
  data: () => ({
    model: 1,
    alertVisible: false,
  }),
  async created() {
    await this.fetchGetProducts();
  },
  computed: {
    ...mapGetters("products", ["getProducts"]),
    ...mapGetters("customer", ["getUser"])
  },
  methods: {
    ...mapActions("products", ["fetchGetProducts"]),
    ...mapActions("shoppingCart", ["addProductInCart"]),
      showAlert() {
      this.alertVisible = true;
      var self = this;
      setTimeout(function () {
        self.alertVisible = false;
      }, 3000);
    },
    async addProductToCart(productId) {
     
      try {
        await this.addProductInCart(productId);
        this.showAlert();
      } catch (error) {
        console.log(error);
       
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.unavailable{
  background:  rgba(224, 32, 32, 1);
  position: absolute;
  transform: rotate(45deg);
  top: 20px;
  left: 70%;
  clip-path: polygon(8% 100%, 27% 0, 81% 0, 100% 100%);
}
.row-unavailable{
  position: absolute;
}
</style>
